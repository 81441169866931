<template>
  <div>
    <b-overlay
      :show="load"
      variant="transparent"
    >
      <b-card class="card-statistics">
        <app-collapse>
          <app-collapse-item title="Filtros">
            <div
              class="mb-1 col-md-5"
            >
              <b-row>
                <label>Selecione os credores</label>
                <v-select
                  id="forma-recebimento"
                  v-model="creditorsFilter"
                  :options="creditors"
                  :close-on-select="false"
                  multiple
                  class="mb-1"
                />
                <b-col class="d-flex justify-content-between mt-3">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click="loadFilter"
                  >
                    Filtrar
                  </b-button>

                  <b-button
                    v-show="selectWithData"
                    variant="outline-warning"
                    @click="exportFile"
                  >
                    Exportar excel
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </app-collapse-item>
        </app-collapse>
      </b-card>
    </b-overlay>
  </div>

</template>

<script>
import {
  BCard, BButton, BCol, BOverlay,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { saveAs } from 'file-saver'
import axios from '@/../axios-auth'

const ExcelJS = require('exceljs')

export default {
  components: {
    BCard,
    BOverlay,
    BCol,
    AppCollapse,
    AppCollapseItem,
    BButton,
    vSelect,
  },

  data() {
    return {
      selectWithData: false,
      load: true,
      dataRequest: [],
      creditors: [],
      creditorsFilter: [],
    }
  },
  async created() {
    await axios.get('api/v1/credores/listar/false', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      this.load = true
      res.data.dados.map(item => {
        this.creditors.push({
          label: item.nome,
          id: item.id,
        })
      })
      this.load = false
    })
  },

  methods: {
    async loadFilter() {
      const credId = []
      this.creditorsFilter.map(item => {
        credId.push(item.id)
      })

      const body = {
        credores_id: credId,
      }
      if (credId.length === 0) {
        this.$swal({
          title: 'Error!',
          text: 'Selecione pelo menos um credor!',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      } else {
        this.load = true
        await axios.post('api/v1/relatorios/backoffice/listar/operacoes', body, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        }).then(res => {
          this.dataRequest = res.data.dados
          this.selectWithData = true
          this.load = false

          this.$swal({
            title: 'Dados prontos para exportação!',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
          })
        })
      }
    },

    async exportFile() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },

    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório')
      const itemsPlan = this.dataRequest

      worksheet.columns = [
        { header: 'Aluno' },
        { header: 'CLIENTE ID', key: 'cliente_id' },
        { header: 'CLIENTE', key: 'cliente_nome' },
        { header: 'CPF/CNPJ', key: 'cpf_cnpj' },
        { header: 'ID CREDOR', key: 'credor_id' },
        { header: 'CREDOR', key: 'credor_nome' },
        { header: 'DATA VENCIMENTO', key: 'data_vencimento' },
        { header: 'OPERACAO ID', key: 'operacao_id' },
        { header: 'NUMERO OPERAÇÃO', key: 'numero_operacao' },
        { header: 'STATUS OPERAÇÃO', key: 'status_operacao_nome' },
        { header: 'PENDÊNCIA', key: 'pendecia' },
        { header: 'VALOR NOMINAL', key: 'valor_nominal' },
        { key: 'numero_remessa', header: 'NÚMERO REMESSA' },
        { key: 'data_remessa', header: 'DATA REMESSA' },
        { key: 'descricao', header: 'DESCRIÇÃO' },
        { key: 'localizacao', header: 'STATUS LOCALIZAÇÃO DO CLIENTE' },
        { header: 'DATA DEVOLUÇÃO', key: 'data_devolucao' },
        { header: 'DEVOLUÇÃO', key: 'motivo_devolucao' },
      ]

      itemsPlan.map(item => {
        worksheet.addRow([
          item.aluno,
          item.cliente_id,
          item.cliente_nome,
          item.cpf_cnpj,
          item.credor_id,
          item.credor_nome,
          this.formatTimezone(item.data_vencimento),
          item.operacao_id,
          item.numero_operacao,
          item.status_operacao_nome,
          item.pendecia,
          parseFloat(this.valorBr(item.valor_nominal, true)),
          item.numero_remessa,
          this.formatTimezone(item.data_remessa),
          item.descricao,
          item.localizacao,
          item.data_devolucao ? this.formatTimezone(item.data_devolucao) : '',
          item.motivo_devolucao ? item.motivo_devolucao : '',
        ])
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório de operações', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },

  },
}

</script>

<style>

</style>
